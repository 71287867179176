// router.js
import { createRouter, createWebHistory } from 'vue-router';
 
 
// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes:[
    { path: '/home',name:"home", component: ()=>{return import("@/view/homePage.vue")} },
    { path: '/terms',name:"terms", component: ()=>{return import("@/view/termsPage.vue")} },
    { path: '/privacy',name:"privacy", component: ()=>{return import("@/view/privacyPage.vue")} },
    { path: '/:catchAll(.*)',redirect:"/home" }
  ]
});
 
export default router;